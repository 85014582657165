/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePatchReviewBoardMutation,
  useGetUserListQuery,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { toast } from 'react-toastify'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { ReviewBoardUpdateValidator } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const reviewBoardSchema = Yup.object().shape<Shape<ReviewBoardUpdateValidator>>({
  userId: Yup.number().required('Le proprio est obligatoire'),
}).required()

type ReviewBoardRequest = FormikContextType<ReviewBoardUpdateValidator>

/* Styled components -------------------------------------------------------- */
const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
`

const FormButton = styled(Button)`
  min-width: 150px;
  height: 100%;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 110px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsBoardChangeAdminModalProps {
  handleClose: () => void;
  userId: number;
  boardId: number;
}

const ReviewsBoardChangeAdminModal: React.FC<ReviewsBoardChangeAdminModalProps> = ({
  handleClose,
  userId,
  boardId,
}) => {

  const {
    currentData: userList = [],
    isFetching: isFetchingUserList,
  } = useGetUserListQuery()
  const [
    submitPatchBoard,
  ] = usePatchReviewBoardMutation()

  const onSubmit = (values: ReviewBoardUpdateValidator, { setSubmitting, resetForm }: FormikHelpers<ReviewBoardUpdateValidator>) => {
    submitPatchBoard({ id: boardId, data: values })
      .then((response) => {
        if (!isApiError(response)) {
          resetForm()
          handleClose()
        } else {
          toast.error('Une erreur est survenue.')
          setSubmitting(false)
        }
      }).catch(console.error)
  }

  const formikForm: ReviewBoardRequest = useForm<ReviewBoardUpdateValidator>(
    {
      initialValues: {
        userId,
      },
      onSubmit: onSubmit,
      validationSchema: reviewBoardSchema,
    },
  )

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <Form form={formikForm}>
        <DialogContent>
          <FormBoldTitle required>
            Admin du board
          </FormBoldTitle>
          <AutocompleteField
            name="userId"
            options={userList.filter((p) => p.userInfo?.firstNameWithInitial).map((p) => p.id)}
            getOptionLabel={(option) => userList.find((p) => p.id === option)?.userInfo?.firstNameWithInitial || ''}
            disabled={isFetchingUserList}
            size="small"
          />
        </DialogContent>
        <DialogActionContainer>
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
            disabled={formikForm.isSubmitting}
          >
            {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Valider'}
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default ReviewsBoardChangeAdminModal
