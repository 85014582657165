/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostProjectImputationMutation,
  useDeleteProjectImputationMutation,
  useGetProjectQuery,
  usePatchProjectMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import NumberField from 'components/FieldWithInputAdornment/NumberField'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ProjectsModalItem from './ProjectsModalItem'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  Imputation,
  ProjectUpdateValidator,
  Client,
  Software,
  ProjectStatus,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const projectBulkSchema = Yup.object().shape<Shape<ProjectUpdateValidator>>({
  clientId: Yup.string(),
  name: Yup.string().required('Le libellé projet est obligatoire'),
  softwareId: Yup.string().required('Le logiciel est obligatoire'),
  statusId: Yup.string().required('Le statut est obligatoire'),
  description: Yup.string(),
  imputations: Yup.array(Yup.object().shape<Shape<Imputation>>({
    id: Yup.number().required(),
    name: Yup.string().required(),
    nbHours: Yup.number().required(),
    projectId: Yup.string().required(),
    imputationId: Yup.string().required(),
  })).required(),
}).required()

type ProjectRequest = FormikContextType<ProjectUpdateValidator>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  margin: 0px 0px 15px;
  padding: 0px;
`

const FormButton = styled(Button)`
  margin: 10px 5px 0px;
  min-width: 150px;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 110px;
  }
`

const ImputationRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 10px;
  align-items: flex-end;
`

const ImputationsContainer = styled.div`
  width: 100%;
`

const HourContainer = styled.div`
  min-width: 150px;
  width: 150px;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 50px;
    min-width: 50px;
  }
`

const ImputationField = styled(Field)`
  margin-bottom: 5px;
`

const ImputationNumberField = styled(NumberField)`
  margin-bottom: 5px;
`

const DeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-bottom: 6px;
  padding-left: 1px;
`

const DeleteButton = styled(Button)`
  height: 38px;
  min-height: 38px;
  width: 40px;
  min-width: 40px;
`

/* Component declaration ---------------------------------------------------- */
interface ProjectsEditModalProps {
  id: string;
  open: boolean;
  handleClose: () => void;
  clientList: Client[];
  isFetchingClientList: boolean;
  softwareList: Software[];
  isFetchingSoftwareList: boolean;
  statusList: ProjectStatus[];
  isFetchingStatusList: boolean;
}

const ProjectsEditModal: React.FC<ProjectsEditModalProps> = ({
  open,
  handleClose,
  id,
  ...props
}) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const {
    currentData: project,
    isFetching: isFetchingProject,
  } = useGetProjectQuery(id)
  const [
    submitEditProject,
  ] = usePatchProjectMutation()
  const [
    submitDeleteProjectImputation,
  ] = useDeleteProjectImputationMutation()
  const [
    submitCreateProjectImputation,
  ] = usePostProjectImputationMutation()

  const formikForm: ProjectRequest = useForm<ProjectUpdateValidator>(
    {
      initialValues: {
        name: '',
        softwareId: 1,
        statusId: 0,
        clientId: '',
        description: '',
        imputations: [],
      },
      validationSchema: projectBulkSchema,
    },
  )

  useEffect(() => {
    if (!isFetchingProject && project) {
      formikForm.setValues({ ...project, imputations: project.imputations ?? []})
    }
  }, [ isFetchingProject ])

  const onSubmit = (): void => {
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) return submitEditProject({ id, data: formikForm.values })
      })
      .then((response) => {
        if (!response) return
        else if (isApiError(response)) {
          toast.error('Une erreur est survenue lors de la modification du projet.')
          formikForm.setSubmitting(false)
        }
        else {
          formikForm.resetForm()
          handleClose()
        }
      })
      .catch(console.error)
  }

  const deleteImputation = (index: number) => {
    const imputations = structuredClone(formikForm.values.imputations ?? [])
    const removedImputations = imputations.splice(index, 1)[0]

    setIsLoading(true)
    submitDeleteProjectImputation(removedImputations.id)
      .then((response) => {
        if (!isApiError(response)) {
          formikForm.setFieldValue('imputations', imputations)
        } else {
          toast.error("Une erreur est survenue lors de la suppression de l'imputation.")
        }
        setIsLoading(false)
      }).catch(console.error)
  }

  const addImputation = () => {
    const randomCode = `${Math.floor(Math.random() * 10)}${ String.fromCharCode(65 + Math.floor(Math.random() * 26))}`

    setIsLoading(true)
    submitCreateProjectImputation({ name: 'Nouvelle imputation', nbHours: 0, projectId: id, imputationId: randomCode })
      .then((response) => {
        if (('data' in response)) {
          formikForm.setFieldValue('imputations', [ ...formikForm.values.imputations ?? [], response.data ])
        } else {
          toast.error("Une erreur est survenue lors de l'ajout de l'imputation.")
          formikForm.setSubmitting(false)
        }
        setIsLoading(false)
      }).catch(console.error)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          {`Modifier ${project?.name ?? 'le projet'}`}
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContent>
          <LoadingOverlay isLoading={isFetchingProject || isLoading}>
            <ProjectsModalItem
              edit
              {...props}
            />
            <ImputationRowContainer>
              <ImputationsContainer>
                <FormBoldTitle smaller>
                  Imputations
                </FormBoldTitle>
                {
                  formikForm.values.imputations?.map((imputation, index) => (
                    <ImputationField
                      key={`${imputation.id}-${index}`}
                      component={TextField}
                      placeholder="Imputation"
                      name={`imputations[${index}].name`}
                      size="small"
                    />
                  ))
                }
              </ImputationsContainer>
              <HourContainer>
                <FormBoldTitle smaller>
                  Nombre d'heures
                </FormBoldTitle>
                {
                  formikForm.values.imputations?.map((imputation, index) => (
                    <ImputationNumberField
                      key={`${imputation.id}-${index}`}
                      placeholder="Durée"
                      name={`imputations[${index}].nbHours`}
                      size="small"
                    />
                  ))
                }
              </HourContainer>
              <DeleteContainer>
                {
                  formikForm.values.imputations?.map((imputation, index) => (
                    <DeleteButton
                      key={`${imputation.id}-${index}`}
                      variant="contained"
                      color="error"
                      disabled={isLoading}
                      onClick={() => deleteImputation(index)}
                    >
                      <DeleteOutlined />
                    </DeleteButton>
                  ))
                }
              </DeleteContainer>
            </ImputationRowContainer>
            <Button
              variant="contained"
              color="secondary"
              disabled={isLoading}
              onClick={addImputation}
            >
              Ajouter une imputation
            </Button>
          </LoadingOverlay>
        </DialogContent>
        <DialogActionContainer>
          <FormButton
            onClick={handleClose}
            variant="outlined"
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            onClick={onSubmit}
            disabled={formikForm.isSubmitting}
          >
            {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Valider'}
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default ProjectsEditModal
