/* Framework imports -------------------------------------------------------- */
import { useMemo } from 'react'
import * as Redux from 'react-redux'

/* Module imports ----------------------------------------------------------- */
import {
  persistor,
  store,
} from 'store/store'
import { getAuthFromLocalStorage } from 'helpers/localStorageHelpers'
import {
  setAuth,
  selectAuthInfo,
  resetAuth,
  defaultAuth,
} from './slices/authSlice'

/* Components imports ------------------------------------------------------- */
import { toast } from 'react-toastify'

/* Type imports ------------------------------------------------------------- */
import type {
  RootState,
  AppDispatch,
} from 'store/store'
import type { Auth } from './slices/authSlice'

/* Generic Redux store hooks ------------------------------------------------ */
export const useAppDispatch: () => AppDispatch = Redux.useDispatch
export const useAppSelector: Redux.TypedUseSelectorHook<RootState> = Redux.useSelector

/* Specific Redux store hooks ----------------------------------------------- */
export const useAuthInfo = (): Auth => {
  const dispatch = useAppDispatch()

  const lLoginInfo: Auth = useAppSelector(
    selectAuthInfo,
    (pLeft: Auth, pRight: Auth): boolean => pLeft.token === pRight.token,
  )

  return useMemo<Auth>((): Auth => {

    if (lLoginInfo.token) {
      return lLoginInfo.token === 'null' ? defaultAuth : lLoginInfo
    }

    const lLocalStorageAuthInfo = getAuthFromLocalStorage()

    if (lLocalStorageAuthInfo) {
      dispatch(setAuth(lLocalStorageAuthInfo))
      return lLocalStorageAuthInfo
    }

    return defaultAuth
  }, [ lLoginInfo, dispatch ])
}

export const onLogoutReset = (force: boolean = false) => {
  if (force) {
    toast.warn('Merci de vous reconnecter', { toastId: 'logout' })
  }
  persistor.pause()
  persistor.flush().then(() => {return persistor.purge()}).catch(console.error)
  store.dispatch(resetAuth())
}
