/* eslint-disable @typescript-eslint/ban-ts-comment */
/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetClientGroupListQuery,
  usePostClientMutation,
  useGetClientListQuery,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import RouteTitle from 'router/RouteTitle'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import LongButton from 'components/LongButton/LongButton'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'
import LoadingOverlay from 'components/Loader/LoadingOverlay'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { ClientStoreValidator } from 'API/__generated__/Api'
import type { Shape } from 'components/FormikLogic/FormikLogic'

/* Types declaration -------------------------------------------------------- */
const clientSchema = Yup.object().shape<Shape<ClientStoreValidator>>({
  id: Yup.string().required('Ce champ est obligatoire'),
  name: Yup.string().required('Ce champ est obligatoire'),
  groupId: Yup.string().nullable().required('Ce champ est obligatoire'),
  address1: Yup.string(),
  address2: Yup.string(),
  address3: Yup.string(),
  zipCode: Yup.string(),
  city: Yup.string(),
  phone: Yup.string().test(
    'phone',
    'Le téléphone est invalide',
    (item = '') => !item || item.replaceAll(' ', '').length === 10,
  ),
  email: Yup.string().email("L'email est invalide"),
  closingEmail: Yup.string().email("L'email est invalide"),
  paymentName: Yup.string(),
  paymentAddress1: Yup.string(),
  paymentAddress2: Yup.string(),
  paymentAddress3: Yup.string(),
  paymentZipCode: Yup.string(),
  paymentCity: Yup.string(),
  contactName1: Yup.string(),
  contactDirectPhone1: Yup.string().test(
    'direct-phone-1',
    'Le téléphone est invalide',
    (item = '') => !item || item.replaceAll(' ', '').length === 10,
  ),
  contactEmail1: Yup.string().email("L'email est invalide"),
  contactName2: Yup.string(),
  contactDirectPhone2: Yup.string().test(
    'direct-phone-2',
    'Le téléphone est invalide',
    (item = '') => !item || item.replaceAll(' ', '').length === 10,
  ),
  contactEmail2: Yup.string().email("L'email est invalide"),
  comment: Yup.string(),
  technicalComment: Yup.string(),
  tva: Yup.number().nullable(),
}).required()

type StoreClientRequest = FormikContextType<ClientStoreValidator>

/* Styled components -------------------------------------------------------- */
const ColContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-start;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const LineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: flex-end;
  align-items: start;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 100px;
  gap: 15px;
  margin-bottom: 10px;
  align-items: start;

  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  margin-top: -15px;
  width: 100%;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

const DoubleContainer = styled(Container)`
  width: calc(100% / 2 - 10px);

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% - 10px);
  }
`

const TripleContainer = styled(Container)`
  width: calc(100% / 3 - 10px);

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% / 3 - 10px);
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.2rem;
`

/* Component declaration ---------------------------------------------------- */
interface NewClientPageProps {}

const NewClientPage: React.FC<NewClientPageProps> = () => {
  const navigate = useNavigate()
  const [ loading, setIsLoading ] = useState<boolean>(false)

  const {
    currentData: groupList = [],
    isFetching: isFetchingGroupList,
  } = useGetClientGroupListQuery()
  const {
    currentData: clientList = [],
    isFetching: isFetchingClientList,
  } = useGetClientListQuery()
  const [
    submitCreateClient,
  ] = usePostClientMutation()

  const formikForm: StoreClientRequest = useForm<ClientStoreValidator>(
    {
      initialValues: {
        id: '',
        name: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        closingEmail: '',
        comment: '',
        contactDirectPhone1: '',
        contactDirectPhone2: '',
        contactEmail1: '',
        contactEmail2: '',
        contactName1: '',
        contactName2: '',
        email: '',
        paymentAddress1: '',
        paymentAddress2: '',
        paymentAddress3: '',
        paymentCity: '',
        paymentName: '',
        paymentZipCode: '',
        phone: '',
        technicalComment: '',
        // @ts-ignore
        tva: null,
        zipCode: '',
        // @ts-ignore
        groupId: null,
      },
      validationSchema: clientSchema,
    },
  )

  const onCreateClient = () => {
    setIsLoading(true)
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) return submitCreateClient(formikForm.values)
      })
      .then((response) => {
        if (response && isApiError(response)) {
          toast.error("Une erreur est survenue lors de l'enregistrement du client.")
          formikForm.setSubmitting(false)
        } else if (response) {
          navigate(-1)
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  const clientCodeHelper = useMemo(() => {
    const { id } = formikForm.values
    if (id.length > 2) {
      const codes = clientList.filter((p) => p.id.toLowerCase().startsWith(id.toLowerCase())).map((p) => p.id).join(', ')
      return codes ? `Codes existants : ${codes}` : ''
    }
  }, [ formikForm.values.id ])

  return (
    <Form form={formikForm}>
      <RouteTitle title="Nouvel Client" />
      <LargeTitle>
        Nouveau Client
        <ButtonsContainer>
          <LongButton
            variant="contained"
            onClick={onCreateClient}
            disabled={loading}
          >
            Valider
          </LongButton>
        </ButtonsContainer>
      </LargeTitle>
      <LoadingOverlay isLoading={loading}>
        <Card>
          <CardContent>
            <Title>
              Informations principales
            </Title>
            <GridContainer>
              <div>
                <FormBoldTitle required>
                  Code
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Code"
                  name="id"
                  size="small"
                  disabled={isFetchingClientList}
                  helperText={clientCodeHelper}
                />
              </div>
              <div>
                <FormBoldTitle required>
                  Nom
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Nom"
                  name="name"
                  size="small"
                />
              </div>
              <div>
                <FormBoldTitle required>
                  Groupe
                </FormBoldTitle>
                <AutocompleteField
                  name="groupId"
                  options={groupList.filter((p) => p.name).map((p) => p.id)}
                  getOptionLabel={(option) => groupList.find((p) => p.id === option)?.name || ''}
                  disabled={isFetchingGroupList}
                  size="small"
                />
              </div>
              <div>
                <FormBoldTitle>
                  TVA
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="TVA"
                  name="tva"
                  size="small"
                />
              </div>
            </GridContainer>
            <LineContainer>
              <TripleContainer>
                <FormBoldTitle>
                  Téléphone
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Téléphone"
                  name="phone"
                  size="small"
                />
              </TripleContainer>
              <TripleContainer>
                <FormBoldTitle>
                  Email
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Email"
                  name="email"
                  size="small"
                />
              </TripleContainer>
              <TripleContainer>
                <FormBoldTitle>
                  Email clotûre
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Email clotûre"
                  name="closingEmail"
                  size="small"
                />
              </TripleContainer>
            </LineContainer>
            <br />
            <LineContainer>
              <DoubleContainer>
                <FormBoldTitle>
                  Description
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Description"
                  name="comment"
                  size="small"
                  multiline
                  rows={6}
                />
              </DoubleContainer>
              <DoubleContainer>
                <FormBoldTitle>
                  Technique
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Technique"
                  name="technicalComment"
                  size="small"
                  multiline
                  rows={6}
                />
              </DoubleContainer>
            </LineContainer>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Title>
              Contact
            </Title>
            <LineContainer>
              <TripleContainer>
                <FormBoldTitle>
                  Responsable 1
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Responsable 1"
                  name="contactName1"
                  size="small"
                />
              </TripleContainer>
              <TripleContainer>
                <FormBoldTitle>
                  Ligne directe 1
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Ligne directe 1"
                  name="contactDirectPhone1"
                  size="small"
                />
              </TripleContainer>
              <TripleContainer>
                <FormBoldTitle>
                  Email responsable 1
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Email responsable 1"
                  name="contactEmail1"
                  size="small"
                />
              </TripleContainer>
            </LineContainer>
            <br />
            <LineContainer>
              <TripleContainer>
                <FormBoldTitle>
                  Responsable 2
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Responsable 2"
                  name="contactName2"
                  size="small"
                />
              </TripleContainer>
              <TripleContainer>
                <FormBoldTitle>
                  Ligne directe 2
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Ligne directe 2"
                  name="contactDirectPhone2"
                  size="small"
                />
              </TripleContainer>
              <TripleContainer>
                <FormBoldTitle>
                  Email responsable 2
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Email responsable 2"
                  name="contactEmail2"
                  size="small"
                />
              </TripleContainer>
            </LineContainer>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Title>
              Adresse & Facturation
            </Title>
            <ColContainer>
              <DoubleContainer>
                <FormBoldTitle>
                  Adresse 1
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Adresse 1"
                  name="address1"
                  size="small"
                />
                <FormBoldTitle>
                  Adresse 2
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Adresse 2"
                  name="address2"
                  size="small"
                />
                <FormBoldTitle>
                  Adresse 3
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Adresse 3"
                  name="address3"
                  size="small"
                />
                <FormBoldTitle>
                  Code postal
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Code postal"
                  name="zipCode"
                  size="small"
                />
                <FormBoldTitle>
                  Ville
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Ville"
                  name="city"
                  size="small"
                />
              </DoubleContainer>
              <DoubleContainer>
                <FormBoldTitle>
                  Adresse facturation 1
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Adresse facturation 1"
                  name="paymentAddress1"
                  size="small"
                />
                <FormBoldTitle>
                  Adresse facturation 2
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Adresse facturation 2"
                  name="paymentAddress2"
                  size="small"
                />
                <FormBoldTitle>
                  Adresse facturation 3
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Adresse facturation 3"
                  name="paymentAddress3"
                  size="small"
                />
                <FormBoldTitle>
                  Code postal facturation
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Code postal facturation"
                  name="paymentZipCode"
                  size="small"
                />
                <FormBoldTitle>
                  Ville facturation
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Ville facturation"
                  name="paymentCity"
                  size="small"
                />
                <FormBoldTitle>
                  Nom de l'adresse de facturation
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Nom adresse facturation"
                  name="paymentName"
                  size="small"
                />
              </DoubleContainer>
            </ColContainer>
          </CardContent>
        </Card>
      </LoadingOverlay>
    </Form>
  )
}

export default NewClientPage
