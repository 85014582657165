/* Framework imports -------------------------------------------------------- */
import React from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostUserMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthInputContainer from 'components/AuthComponents/AuthInputContainer'
import AuthButtonsContainer from 'components/AuthComponents/AuthButtonsContainer'
import FormikPasswordInput from 'components/AuthComponents/FormikPasswordInput'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { UserStoreValidator } from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const ForgotPasswordFormSchema = Yup.object().shape({
  email: Yup.string().email("L'email est invalide").required("L'email est invalide"),
  password: Yup.string().required('Le mot de passe est invalide'),
})

/* Component declaration ---------------------------------------------------- */
interface FirstLoginPageProps {}

const FirstLoginPage: React.FC<FirstLoginPageProps> = () => {
  const navigate = useNavigate()

  const [
    firstLogin,
  ] = usePostUserMutation()

  const onSubmit = (values: UserStoreValidator, { setSubmitting }: FormikHelpers<UserStoreValidator> ): void | Promise<void> => {
    firstLogin(values).then((response) => {
      setSubmitting(false)
      if (isApiError(response)) {
        let msg = ''
        if ((JSON.parse(response.error.errors) as unknown as {field: string}[])?.[0]?.field === 'email') {
          msg = ` : Un compte avec cet email existe deja ou l'email est invalide.`
        } else {
          msg = '.'
        }
        toast.error(`Une erreur est survenue${msg}.`)
      } else {
        toast.success(`Votre compte à bien été créé ! Vous pouvez maintenant vous connecter.`)
        navigate('/connexion')
      }
    }).catch(console.error)
  }

  const formikForm = useForm<UserStoreValidator>(
    {
      initialValues: {
        email: '',
        password: '',
      },
      onSubmit: onSubmit,
      validationSchema: ForgotPasswordFormSchema,
    },
  )

  const onCancelClick = () => {
    navigate('/connexion')
  }

  return (
    <AuthContainer>
      <Form form={formikForm}>
        {formikForm.isSubmitting && <Loader />}
        <AuthWelcomeMessage>
          Première connexion ?
        </AuthWelcomeMessage>
        <div>
          <AuthInputContainer>
            <FormBoldTitle>
              Email
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Email"
              name="email"
              autoComplete="username"
            />
          </AuthInputContainer>
          <AuthInputContainer>
            <FormBoldTitle>
              Mot de passe
            </FormBoldTitle>
            <FormikPasswordInput
              name="password"
              autoComplete="current-password"
            />
          </AuthInputContainer>
        </div>
        <AuthButtonsContainer>
          <Button
            type="submit"
            disabled={formikForm.isSubmitting}
            variant="contained"
            fullWidth
          >
            {
              formikForm.isSubmitting ?
                `Création du compte en cours...` :
                `Créer mon compte`
            }
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disabled={formikForm.isSubmitting}
            onClick={onCancelClick}
          >
            Annuler
          </Button>
        </AuthButtonsContainer>
      </Form>
    </AuthContainer>
  )
}

export default FirstLoginPage
