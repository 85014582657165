/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import {
  resetAuthInLocalStorage,
  setAuthInLocalStorage,
} from 'helpers/localStorageHelpers'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'
import type { Nullable } from 'types/Nullable'
import type { User } from 'API/__generated__/Api'

interface Tokens {
  token?: string;
  teamViewerRefreshToken?: string;
  teamViewerAccessToken?: string;
  teamViewerCode?: string;
}

export type Auth = Tokens & Nullable<User>

export const defaultAuth: Auth = {
  id: 0,
  isAdmin: false,
  teamViewerAccessToken: undefined,
  teamViewerCode: undefined,
  teamViewerRefreshToken: undefined,
  email: '',
  updatedAt: '',
  createdAt: '',
  avatarColor: '',
  firstNameWithInitial: '',
  fullName: '',
  initials: '',
  token: undefined,
}

/* Redux slice -------------------------------------------------------------- */
const authSlice = createSlice(
  {
    name: 'auth',
    initialState: defaultAuth,
    reducers: {
      setAuth: (state, { payload }: PayloadAction<Auth>) => {
        setAuthInLocalStorage(payload)
        OpenAPI.setSecurityData(payload)
        return payload
      },
      updateProfile: (state, { payload }: PayloadAction<Auth>) => {
        const newPayload = { ...state, ...payload }
        setAuthInLocalStorage(newPayload)
        return newPayload
      },
      setAuthTeamViewerTokens: (state, { payload }: PayloadAction<Tokens>) => {
        if (payload.teamViewerRefreshToken) {
          state.teamViewerRefreshToken = payload.teamViewerRefreshToken
        } if (payload.teamViewerAccessToken) {
          state.teamViewerAccessToken = payload.teamViewerAccessToken
        }
      },
      setAuthTeamViewerCode: (state, { payload }: PayloadAction<Tokens>) => {
        state.teamViewerCode = payload.teamViewerCode
      },
      resetAuth: () => {
        resetAuthInLocalStorage()
        OpenAPI.setSecurityData(null)
        return ({ ...defaultAuth, token: 'null' })
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const {
  setAuth,
  updateProfile,
  setAuthTeamViewerTokens,
  setAuthTeamViewerCode,
  resetAuth,
} = authSlice.actions

export default authSlice.reducer

export const selectAuthInfo = (state: RootState): Auth => {
  return state.auth
}
